<template lang="pug">
modal(size='sm' admin @close='$emit("close")')
  generic-form(:id='`Form${type}MemberAddAdmin`' :endpoint='`/_/${type}/${id}/Members/*`' :allowShortcuts='true' @submitSuccess='$emit("close")')
    template(#default='{ config }')
      form-header
        span(v-html='config.label')
      form-select-async(name='UserEmail' :lable='$t("Benutzer")' :controlProps='{ fetchOptions }')
      form-footer
        form-action(:primary='true' name='AddUser')
</template>

<script lang="ts">
import { type UpdateFn } from '@/components/Control/ControlMultiSelectAsync.vue'
import { type IPaginatedResponse } from '@/types'
import axios from 'axios'
import { type PropType } from 'vue'
import { defineComponent } from 'vue'

export const fetchOptions = async (query: string = '', update: UpdateFn) => {
  const { data } = await axios.get<IPaginatedResponse<{ email: string; familyName: string; givenName: string }>>(
    `/Administration/Users`,
    {
      params: { params: { q: query, from: 0, size: 40 } },
    },
  )
  update(data.hits.map((hit) => ({ value: hit.email, label: `${hit.givenName} ${hit.familyName}` })))
}
export type MemberType = 'Customers' | 'Suppliers'
const FormMemberAddAdmin = defineComponent({
  props: {
    id: { type: String, required: true },
    type: { type: String as PropType<MemberType>, required: true },
  },
  setup: () => {
    return {
      fetchOptions,
      userOptions: [],
    }
  },
})
export default FormMemberAddAdmin
</script>
