<template lang="pug">
modal(size='sm' admin @close='$emit("close")')
  modal-header(icon='user-plus') {{ $t('Projekt übertragen an:') }}
  generic-form(:endpoint='`/_/Projects/${projectId}/UpdateOwner`' @submitSuccess='onSubmitSuccess')
    form-select-async(name='UserEmail' :lable='$t("Benutzer")' :controlProps='{ fetchOptions }')
    form-footer
      form-action(:primary='true' name='Save')
</template>

<script setup lang="ts">
import { useProjectsStore } from '../store'
import { type IProjectData } from '../types'
import { type IFormResponse } from '@/components/GenericForm/types'
import { fetchOptions } from '@/forms/FormMemberAddAdmin.vue'
import { defineProps } from 'vue'

defineProps({
  projectId: { type: String, required: true },
})
const store = useProjectsStore()
const emit = defineEmits(['close'])
const onSubmitSuccess = (response: IFormResponse<IProjectData>) => {
  console.log('response', response)
  store.setProject(response.data!)
  emit('close')
}
</script>
